import { Analytics } from "@vercel/analytics/react"
import { lazy, Suspense } from 'react';
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { countryInsightsData, globalReportsData } from "./constants/Data.js";
import data from "./constants/static.json";
import { ErrorPage } from './pages';
import { Footer, Loader, Navbar } from './components';
import './css/main.css';
import UserAgreement from './pages/byor/byor.js';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import TagManager from 'react-gtm-module'
import { config } from './config.js';
const tagManagerArgs = {
  gtmId: config.gtm_id
}
TagManager.initialize(tagManagerArgs)

const Success = lazy(() => import('./pages/StripePayment/Success.jsx'));
const Cancel = lazy(() => import('./pages/StripePayment/Cancel.jsx'));

const MyAccountLazy = lazy(() =>
	import(
		'./pages/UserDashboard/MyAccount/MyAccount.router.jsx'
	)
);

const BusinessMatchmakingLazy = lazy(() => import('./pages/BusinessMatchmaking/BusinessMatchmaking.router.jsx'));

const HireResearchAnalystsLazy = lazy(() => import('./pages/HireResearchAnalysts/HireResearchAnalysts.router.jsx'));

const Byor = lazy(() => import('./pages/byor/byor.jsx'));
const Thankyou = lazy(() =>
	import('./pages/byor/ThankYouPage.jsx')
);
const HomeLazy = lazy(() =>
	import('./pages/Home/Home.router')
);
const CareersLazy = lazy(() =>
	import('./pages/Careers/Careers.router')
);
const LoginLazy = lazy(() =>
	import('./pages/Authentication/Login.router.jsx')
);
const RegisterLazy = lazy(() =>
	import('./pages/Authentication/Register.router.jsx')
);
const TeamsLazy = lazy(() =>
	import('./pages/Teams/Teams.router')
);
const AboutLazy = lazy(() =>
	import('./pages/About/About.router')
);
const CapabilitiesLazy = lazy(() =>
	import('./pages/Capabilities/Capabilities.router')
);
const StoreLazy = lazy(() =>
	import('./pages/Order/Store/Store.router')
);
const OrderDetailsLazy = lazy(() =>
	import('./pages/Order/OrderDetails/OrderDetails.router')
);
const CartLazy = lazy(() =>
	import('./pages/Order/Cart/Cart.router')
);
const BlogLazy = lazy(() =>
	import('./pages/Blogs/Blog/Blog.router')
);
const BlogPostLazy = lazy(() =>
	import('./pages/Blogs/BlogPost/BlogPost.router')
);
const ReportsDetailsLazy = lazy(() =>
	import(
		'./pages/Reports/ReportsDetails/ReportsDetails.router'
	)
);
const AdminDashboardLazy = lazy(() =>
	import(
		'./pages/Admin/AdminDashboard/AdminDashboard.router'
	)
);
const ItemSelectionLazy = lazy(() =>
	import('./pages/ItemSelection/ItemSelection.router')
);
const CheckoutLazy = lazy(() =>
	import('./pages/Checkout/Checkout.router')
);
// const RdPublishingLazy = lazy(() => import('./pages/RDPublishing/RdPubslishing.router'));
const StoreInsideLazy = lazy(() =>
	import('./pages/StoreInside/StoreInside.router')
);
const ContactLazy = lazy(() =>
	import('./pages/Contact/Contact.router')
);
const ReportPublish = lazy(() =>
	import(
		'./pages/Reports/ReportPublish/ReportPublish.router.jsx'
	)
);
const CompetitiveLandscapeLazy = lazy(() =>
	import(
		'./pages/CompetitiveLandscape/CompetitiveLandscape.router'
	)
);
const ResearchLazy = lazy(() =>
	import('./pages/Research/Research.router')
);
const ComingSoonLazy = lazy(() =>
	import('./pages/ComingSoon/ComingSoon.router')
);
const Reports = lazy(() =>
	import('./pages/Reports/index.js')
);
const RefundPolicyLazy = lazy(() =>
	import('./pages/RefundPolicy/RefundPolicy.router.jsx')
);
const PrivacyLazy = lazy(() =>
	import('./pages/Privacy/Privacy.router.jsx')
);
const CookiePolicyLazy = lazy(() =>
	import('./pages/CookiePolicy/CookiePolicy.router.jsx')
);

const DisclaimerPolicyLazy = lazy(() => import("./pages/DisclaimerPolicy/DisclaimerPolicy.router.jsx"));

const HowToOrderLazy = lazy(() => import("./pages/HowToOrder/HowToOrder.router.jsx"));

const PaymentOptionsLazy = lazy(() => import("./pages/PaymentOptions/PaymentOptions.router.jsx"));

const ReportDeliveryLazy = lazy(() => import("./pages/ReportDelivery/ReportDelivery.router.jsx"));

const FaqsLazy = lazy(() => import("./pages/Faqs/Faqs.router.jsx"));

const NewsDetailsLazy = lazy(() => import("./pages/News/NewsDeatils.router.jsx"));

const NewsLazy = lazy(() => import("./pages/News/News.router.jsx"));

const ResetPasswordLazy = lazy(() => import("./pages/ResetPassword/ResetPassword.router.jsx"));

const ForgotPasswordLazy = lazy(() => import("./pages/ForgotPassword/ForgotPassword.router.jsx"));

const App = () => {
	const location = useLocation();
	const globalReportsButtonData = data.globalReportsButtonData;
	const countryInsightsButtonData = data.countryInsightsButtonData;
	return (
		<>
			<Analytics />
			<ScrollToTop />
			{/* dont use navbar for /byor */}
			{
				['/byor', '/thank-you'].includes(location.pathname) ? null : <Navbar />
			}
			<Routes>
				{/* RESET PASSWORD */}
				{/* <Route
					path="/forgot-password"
					element={
						<Suspense fallback={<Loader />}>
							<ForgotPasswordLazy />
						</Suspense>
					}
				/> */}

				{/* STRIPE PAYMENT */}
				{/* <Route
					path='/success'
					element={
						<Suspense fallback={<Loader />}>
							<Success />
						</Suspense>
					}
				/>

				<Route
					path='/cancel'
					element={
						<Suspense fallback={<Loader />}>
							<Cancel />
						</Suspense>
					}
				/> */}

				{/* RESET PASSWORD */}
				{/* <Route
					path="/reset-password"
					element={
						<Suspense fallback={<Loader />}>
							<ResetPasswordLazy />
						</Suspense>
					}
				/> */}
				{/* FAQs */}
				<Route
					path="/faqs"
					element={
						<Suspense fallback={<Loader />}>
							<FaqsLazy />
						</Suspense>
					}
				/>
				{/* REPORT DELIVERY & CUSTOMIZATION PROCESS */}
				<Route
					path="/report-delivery-and-customization"
					element={
						<Suspense fallback={<Loader />}>
							<ReportDeliveryLazy />
						</Suspense>
					}
				/>
				{/* PAYMENT OPTIONS */}
				<Route
					path="/payment-options"
					element={
						<Suspense fallback={<Loader />}>
							<PaymentOptionsLazy />
						</Suspense>
					}
				/>
				{/* BUSINESS MATCHMAKING */}
				<Route
					path="/business-matchmaking"
					element={
						<Suspense fallback={<Loader />}>
							<BusinessMatchmakingLazy />
						</Suspense>
					}
				/>
				{/* HIRE RESEARCH ANALYSTS */}
				<Route
					path="/hire-research-analysts"
					element={
						<Suspense fallback={<Loader />}>
							<HireResearchAnalystsLazy />
						</Suspense>
					}
				/>
				{/* HOW TO ORDER */}
				<Route
					path="/how-to-order"
					element={
						<Suspense fallback={<Loader />}>
							<HowToOrderLazy />
						</Suspense>
					}
				/>
				{/* DISCLAIMER POLICY */}
				<Route
					path="/disclaimer-policy"
					element={
						<Suspense fallback={<Loader />}>
							<DisclaimerPolicyLazy />
						</Suspense>
					}
				/>
				{/* <Route
					path='/my-account'
					element={
						<Suspense fallback={<Loader />}>
							<MyAccountLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/thank-you'
					element={
						<Suspense fallback={<Loader />}>
							<Thankyou />
						</Suspense>
					}
				/>
				<Route
					path='/byor'
					element={
						<Suspense fallback={<Loader />}>
							<Byor />
						</Suspense>
					}
				/>
				<Route
					path='/byor-agreement'
					element={
						<Suspense fallback={<Loader />}>
							<UserAgreement />
						</Suspense>
					}
				/>
				{/* <Route
					path='/sign-up'
					element={
						<Suspense fallback={<Loader />}>
							<RegisterLazy />
						</Suspense>
					}
				/> */}
				<Route path='*' element={<ErrorPage />} />
				<Route
					path='/'
					element={
						<Suspense fallback={<Loader />}>
							<HomeLazy />
						</Suspense>
					}
				/>
				{/* <Route
					path='/sign-in'
					element={
						<Suspense fallback={<Loader />}>
							<LoginLazy />
						</Suspense>
					}
				/>
				<Route
					path='/sign-up'
					element={
						<Suspense fallback={<Loader />}>
							<RegisterLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/industry-outlook'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/market-database'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				{/* INDUSTRY NEWS */}
				<Route
					path="/industry-news"
					element={
						<Suspense fallback={<Loader />}>
							<NewsLazy />
						</Suspense>
					}
				/>
				{/* INDUSTRY NEWS DETAILS */}
				<Route
					path="/industry-news/:id"
					element={
						<Suspense fallback={<Loader />}>
							<NewsDetailsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/mega-trends'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				{/* <Route
					path='/whitepapers'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/company/meet-our-team'
					element={
						<Suspense fallback={<Loader />}>
							<TeamsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/company/careers'
					element={
						<Suspense fallback={<Loader />}>
							<CareersLazy />
						</Suspense>
					}
				/>
				{/* GLOBAL & REGIONAL REPORTS */}
				{/* <Route
					path="/capabilities"
					element={
						<Suspense fallback={<Loader />}>
							<CapabilitiesLazy data={globalReportsData} buttonsData={globalReportsButtonData} />
						</Suspense>
					}
				/> */}
				{/* COUNTRY INSIGHTS */}
				<Route
					path="/country-insights"
					element={
						<Suspense fallback={<Loader />}>
							<CapabilitiesLazy data={countryInsightsData} buttonsData={countryInsightsButtonData} />
						</Suspense>
					}
				/>
				<Route
					path='/company/about-us'
					element={
						<Suspense fallback={<Loader />}>
							<AboutLazy />
						</Suspense>
					}
				/>
				<Route
					path='/competitive-landscape'
					element={
						<Suspense fallback={<Loader />}>
							<CompetitiveLandscapeLazy />
						</Suspense>
					}
				/>
				<Route
					path='/research'
					element={
						<Suspense fallback={<Loader />}>
							<ResearchLazy />
						</Suspense>
					}
				/>
				{/* <Route
					path='/store'
					element={
						<Suspense fallback={<Loader />}>
							<StoreLazy />
						</Suspense>
					}
				/> */}
				{/* <Route
					path='/store/:id'
					element={
						<Suspense fallback={<Loader />}>
							<OrderDetailsLazy />
						</Suspense>
					}
				/> */}
				{/* <Route
					path='/cart'
					element={
						<Suspense fallback={<Loader />}>
							<CartLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/blogs'
					element={
						<Suspense fallback={<Loader />}>
							<BlogLazy />
						</Suspense>
					}
				/>
				<Route
					path='/blogs/:id'
					element={
						<Suspense fallback={<Loader />}>
							<BlogPostLazy />
						</Suspense>
					}
				/>
				<Route
					path='/reports'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/reports/:category'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/reports/:category/:subcategory'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/report/:id'
					element={
						<Suspense fallback={<Loader />}>
							<ReportsDetailsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/publish/report'
					element={
						<Suspense fallback={<Loader />}>
							<ReportPublish />
						</Suspense>
					}
				/>
				{/* <Route
					path='/dashboard'
					element={
						<Suspense fallback={<Loader />}>
							<AdminDashboardLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/select-items'
					element={
						<Suspense fallback={<Loader />}>
							<ItemSelectionLazy />
						</Suspense>
					}
				/>
				{/* <Route
					path='/checkout'
					element={
						<Suspense fallback={<Loader />}>
							<CheckoutLazy />
						</Suspense>
					}
				/> */}

				{/* <Route
					path='/store-inside'
					element={
						<Suspense fallback={<Loader />}>
							<StoreInsideLazy />
						</Suspense>
					}
				/> */}
				<Route
					path='/company/contact-us'
					element={
						<Suspense fallback={<Loader />}>
							<ContactLazy />
						</Suspense>
					}
				/>
				<Route
					path='/refund-policy'
					element={
						<Suspense fallback={<Loader />}>
							<RefundPolicyLazy />
						</Suspense>
					}
				/>
				<Route
					path='/privacy'
					element={
						<Suspense fallback={<Loader />}>
							<PrivacyLazy />
						</Suspense>
					}
				/>
				<Route
					path='/cookie-policy'
					element={
						<Suspense fallback={<Loader />}>
							<CookiePolicyLazy />
						</Suspense>
					}
				/>
			</Routes>
			{
				['/byor', '/thank-you'].includes(location.pathname) ? null : <Footer />
			}
		</>
	);
};

const AppWrapper = () => {
	return (
		<BrowserRouter>
			<App />
		</BrowserRouter>
	);
};

export default AppWrapper;
