import React, { useState, useEffect } from 'react';
import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';
import { config } from '../../config';
// import data from "../../constants/static.json";

const Hero = ({ data, buttonText, utmParams }) => {
	const slides = data;
	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		const slideInterval = setInterval(() => {
			setCurrentSlide(
				(prevSlide) => (prevSlide + 1) % slides.length
			);
		}, 3000);

		return () => clearInterval(slideInterval);
	}, []);

	// Handle clicking on a dot
	const handleDotClick = (index) => {
		setCurrentSlide(index);
	};

	return (
		<section className='hero'>
			<div className='hero__slide'>
				<img
					src={`${config.backend.strapi}${slides[currentSlide].bgImage.formats.large.url}`}
					alt=''
					className='hero__image'
				/>
				<div className='hero__Container'>
					<div className='hero__Left'>
						{/* <h4>{slides[currentSlide].title}</h4> */}
						<h1>
							{slides[currentSlide]?.largeText?.split(' ')
								.map((word, i) =>
									i === 1 ? (
										<span key={i}>{word} </span>
									) : (
										word + ' '
									)
								)}
						</h1>
						<p>{slides[currentSlide].smallText}</p>
						<Link
							to={
								utmParams
									? `/byor?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
									: '/byor'
							}
						>
							<Button
								text={buttonText ?? 'frrr'}
								// className='button__Common2 hero__Adjustment shadow-sm shadow-black hover:shadow-none focus:shadow-none text-[16px] font-[400]'

								className='border-black/80 border-[1px] w-[26.7rem] px-[1rem] py-[1.5rem] hero__Adjustment shadow-sm shadow-black hover:shadow-none focus:shadow-none text-[16px] font-[400] rounded-xl'
							/>
						</Link>
					</div>
					<div className='hero__Right'></div>
				</div>
			</div>

			{/* Dot pagination */}
			<div className='hero__dots'>
				{slides.map((_, index) => (
					<span
						key={index}
						className={`hero__dot ${index === currentSlide ? 'active' : ''
							}`}
						onClick={() => handleDotClick(index)}
					></span>
				))}
			</div>
		</section>
	);
};

export default Hero;
