import data from "../../constants/static.json";
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons'

// Footer Data "Solutions"
// {
// 	"id": 1,
// 	"name": "Blogs",
// 	"url": "/blogs"
//   },
//   {
// 	"id": 2,
// 	"name": "Industry News",
// 	"url": "/industry-news"
//   },

const Footer = () => {
	const FooterData = data.FooterData;
	const location = useLocation();
	const [utmParams, setUtmParams] = useState(null);

	const fetchUtmParams = () => {
		setUtmParams(JSON.parse(localStorage.getItem("utm_params")));
	};

	useEffect(() => {
		fetchUtmParams();
	}, []);

	return (
		<footer className='footer'>
			<div className='footer__Container'>
				<div className="footer__Media">
					<div className="footer__MediaLeft">
						Media Citations:
					</div>
					<div className="footer__MediaRight">
						{/* H1 - H4 */}
						<h4 className="text-[21px] font-[400] text-[#01212E] mb-4 max-xl:text-2xl xl:block hidden">Media Citations:</h4>
						<img src="/bloomberg.png" alt="bloomberg" />
						<img src="/cnn.png" alt="cnn" />
						<img src="/forbes.png" alt="forbes" />
						<img src="/globenewswire.png" alt="globenewswire" />
						<img src="/prnewswire.png" alt="prnewswire" />
						<img src="/yahoo.png" alt="yahoo" />
					</div>
				</div>
				<div className="h-0 border-b-[0.1rem] border-b-black w-full mt-[1.5rem]" />
				{/* Footer Top */}
				<div className='footer__Top'>
					<Link
						to={
							utmParams ?
								`/?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
								:
								'/'
						}
						className='footer__Top--Left'
					>
						<img src='/HMSLogo.png' alt='hms-logo' />
					</Link>
					<div className='footer__Top--Right'>
						<Link
							to='https://www.linkedin.com/company/horizon-market-strategies/'
							target='_blank'>
							{/* <img
								src='/LinkedInIcon.svg'
								alt='linkedin-icon'
							/> */}
							{/* <LinkedinIcon className="w-16 h-16 hover:text-blue-800" /> */}
							<SocialIcon network="linkedin" style={{ height: 40, width: 40 }} />
						</Link>
						<Link
							to='https://www.youtube.com/@Horizon-Market-Strategies'
							target='_blank'>
							{' '}
							{/* <img
								src='/youtubeFooterIcon.webp'
								alt='youtube-icon'
							/> */}
							{/* <YoutubeIcon className="w-20 h-20 hover:text-red-600" /> */}
							<SocialIcon network="youtube" style={{ height: 40, width: 40 }} />
						</Link>
						<Link
							to='https://x.com/HorizonMarkets'
							target='_blank'>
							{' '}
							{/* <img
								src='/x-logo.png'
								alt='x-icon'
							/> */}
							{/* <TwitterIcon className="w-16 h-16 hover:text-blue-400" /> */}
							<SocialIcon network="x" style={{ height: 40, width: 40 }} />
						</Link>
					</div>
				</div>
				{/* Footer Middle */}
				<div className='footer__Middle'>
					{FooterData.map((item) => (
						<div className='footer__Links' key={item.id}>
							<h3>{item.title}</h3>
							{item.links.map((link) => (
								<div className='footer__Link' key={link.id}>
									<Link
										to={
											utmParams ?
												`${link.url}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
												:
												link.url
										}
										className='footer__Item'>
										{link.name}
									</Link>
								</div>
							))}
						</div>
					))}
				</div>
				{/* Contact */}
				{/* <div className='footer__Number'>
					<a href='tel:+447700142117'>+44 7700 142117</a>
					<a href='tel:+12136167815'>+1 213 616 7815</a>
				</div> */}
				<div className="h-0 border-b-[0.1rem] border-b-black w-full mt-[1.5rem]" />
				{/* Footer Bottom */}
				<div className="footer__Bottom">
					{/* <p><a href='mailto:info@horizon-markets.com'>Write Us</a></p> */}
					<p>
						<Link
							to={
								utmParams ?
									`/cookie-policy?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
									:
									'/cookie-policy'
							}
							className="hover:text-[#052F3F] hover:underline"
						>Cookie Policy</Link>
					</p>
					<p>
						<Link
							to={
								utmParams ?
									`/privacy?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
									:
									'/privacy'
								}
							className="hover:text-[#052F3F] hover:underline"
						>Privacy Policy</Link>
					</p>
					<p>
						<Link
							to={
								utmParams ?
									`/refund-policy?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
									:
									'/refund-policy'
							}
							className="hover:text-[#052F3F] hover:underline"
						>Terms & Conditions</Link>
					</p>
					<p>© Horizon Market Strategies {new Date().getFullYear()}. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
