import React from 'react';

const UserAgreement = () => {
  return (
    <main className="agreement px-14">
      {/* HEADER */}
      <h1 className="text-[#1E1E1E] text-[16px] font-[400] mb-[3rem] text-justify">
        Build Your Own Report: Comprehensive User Agreement
      </h1>
      {/* PRIVACY POLICY */}
      <section className="text__Wrapper">
        <h2 className="text-[14px] font-[400]">Privacy Policy :</h2>
        <p>
          At Horizon Market Strategies (HMS), we are
          committed to protecting your personal information.
          When you use our "Build Your Own Report" tool, we
          collect the following information:
        </p>
        <ul>
          <li>
            <strong>Personal Information :</strong> Your
            first name, last name, company website, email
            address, and phone number.
          </li>
          <li>
            <strong>Report Customization Details :</strong>{' '}
            Information you provide in the questionnaire to
            create your custom report.
          </li>
        </ul>
        <p>
          We use this information to deliver your report and
          improve our services. We do not sell or share your
          personal information with third parties without
          your consent. You have the right to access,
          correct, or delete your personal data at any time.
        </p>
      </section>
      {/* DISCLAIMER */}
      <section className="text__Wrapper_New">
        <h2>Disclaimer :</h2>
        <p>
          The information provided through the "Build Your
          Own Report" tool is for general informational
          purposes only. While we strive to provide accurate
          and up-to-date information, we make no guarantees
          regarding the completeness or reliability of the
          content. Your use of this tool is at your own
          risk.
        </p>
      </section>
      {/* TERMS & CONDITIONS */}
      <section className="text__Wrapper_New">
        <h2>Terms and Conditions :</h2>
        <ul>
          <li>
            <strong>Customization :</strong> You can
            customize any report through our tool based on
            your specific requirements. The final price will
            be determined based on the complexity of your
            request.
          </li>
          <li>
            <strong>Delivery :</strong> After completing the
            questionnaire and making the payment, we will
            deliver your custom report within a reasonable
            timeframe. Delivery times may vary based on the
            complexity of the report.
          </li>
          <li>
            <strong>Payment :</strong> All payments must be
            made in full before the report is delivered.
          </li>
        </ul>
      </section>
      {/* COOKIE POLICY */}
      <section className="text__Wrapper_New">
        <h2>Cookie Policy :</h2>
        <p>
          We use cookies on our website to enhance your
          experience. Cookies are small text files that help
          us understand how you use our site. You can manage
          your cookie preferences through your browser
          settings. By using our site, you consent to our
          use of cookies.
        </p>
      </section>
      {/* RETURN POLICY */}
      <section className="text__Wrapper_New">
        <h2>Return Policy :</h2>
        <p>
          Due to the customized nature of the reports
          created using our tool, we do not accept returns
          once the report has been delivered. We recommend
          reviewing your requirements carefully before
          submitting your order.
        </p>
      </section>
      {/* REFUND POLICY */}
      <section className="text__Wrapper_New">
        <h2>Refund Policy :</h2>
        <p>
          Refunds are not available after the delivery of
          the custom report. If you have concerns about the
          content or quality of the report, please{' '}
          <a
            href="/company/contact-us"
            target="_blank"
            className="text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
          >
            contact us
          </a>
          , and we will work to address your issues.
        </p>
      </section>
      {/* FAQS */}
      <section className="text__Wrapper_New">
        <h2>FAQs</h2>
        <ol>
          <li>
            <strong>
              How does the "Build Your Own Report" tool
              work?
            </strong>
            <p>
              You fill out a short questionnaire on our
              website, providing details about the report
              you want. After submitting your information
              and requirements, we will reach out to you
              with a final scope of work and pricing
              details.
            </p>
          </li>
          <li>
            <strong>
              How long will it take to receive my custom
              report?
            </strong>
            <p>
              Delivery times vary based on the complexity of
              your request, but we aim to deliver your
              report within a reasonable timeframe after
              receiving payment.
            </p>
          </li>
          <li>
            <strong>
              Can I make changes to my report after I submit
              the questionnaire?
            </strong>
            <p>
              Once you submit your request and payment,
              changes may not be possible. Please review
              your requirements carefully before submitting.
            </p>
          </li>
          <li>
            <strong>
              Is my personal information safe?
            </strong>
            <p>
              Yes, we implement strict security measures to
              protect your personal information. We do not
              share your data with third parties without
              your consent.
            </p>
          </li>
          <li>
            <strong>
              How can I contact you if I have questions?
            </strong>
            <p>
              You can reach us through our website’s{' '}
              <a
                href="/company/contact-us"
                target="_blank"
                className="text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
              >
                contact form
              </a>{' '}
              or email us at{' '}
              <a
                href="mailto:inquiry@horizon-markets.com"
                className="text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
              >
                inquiry@horizon-markets.com
              </a>
              .
            </p>
          </li>
        </ol>
      </section>
      {/* CONTACT US */}
      <section className="agreement__Contact">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about our "Build Your
          Own Report" tool or any of the policies mentioned
          above, please contact us at:
        </p>
        <address>
          Horizon Market Strategies
          <br />
          Email:{' '}
          <a
            href="mailto:inquiry@horizon-markets.com"
            className=" text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
          >
            inquiry@horizon-markets.com
          </a>
          <br />
          <div className="agreement__phone">
            Phone:{' '}
            <a
              href="tel:+13473542413"
              className=" text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
            >
              +1 (347) 354-2413 (US)
            </a>
            ,
            <a
              href="tel:+442037695086"
              className=" text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
            >
              +44 20 3769 5086 (UK)
            </a>
            ,
            <a
              href="tel:+918134945168"
              className=" text-[#0000FF] visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2"
            >
              +91 81349 45168 (IN)
            </a>
          </div>
          Address: Team Karimganj HQ, SC Lane (Sukkur Choudhury Lane), Settlement Ln, opposite of DRDA Office, Karimganj, Assam 788712, India
        </address>
      </section>
    </main>
  );
};

export default UserAgreement;
