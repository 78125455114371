import React, { useEffect, useState, useRef } from 'react';
import {
	Link,
	NavLink,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import Button from '../Button/Button.component';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../constants/token.constant';
import Token from '../../lib/token';
import CookieToken from '../../lib/cookie-token';
import { jwtDecode } from 'jwt-decode';
import { isTokenExpired } from '../../lib/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../store/selector/selector';
import { fetchCategory } from '../../store/reducers/categories';
import navLinks from '../../constants/static.json';
import { UserCircle, Search, XIcon } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '../Modal/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '../Modal/Popover';
import { apiClient } from '../../repositories/apiclient';
import Loader from '../Loader/Loader.component';

const Navbar = () => {
	const location = useLocation();
	const companyLinks = navLinks.companyLinks;
	const resourcesLinks = navLinks.articleLinks;
	const isLoading = false;
	const error = null;
	const dispatch = useDispatch();
	const category = useSelector(selectCategory);
	const navigate = useNavigate();

	const token = CookieToken.getToken(ACCESS_TOKEN_KEY);
	const [user, setUser] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [searchModal, setSearchModal] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);
	const [topBarContent, setTopBarContent] = useState(null);
	const [utmParams, setUtmParams] = useState(null);

	// const isReportDetailsPage = location.pathname.startsWith("/report/");

	const handleDownloadSample = () => {
		console.log("Download Free Sample clicked!");
	};

	const handleMenuToggle = (menu) => {
		setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
	};


	const openSearchModal = () => {
		setSearchModal(true);
	};

	const closeSearchModal = () => {
		setSearchModal(false);
	};

	const logout = () => {
		document.cookie = `${ACCESS_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		document.cookie = `${REFRESH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		localStorage.removeItem('userData');
		setUser(null);
		setLoggedIn(false);
		navigate('/sign-in');
	};

	useEffect(() => {
		dispatch(fetchCategory());
		if (token) {
			if (!isTokenExpired(token)) {
				const usr = jwtDecode(token);
				setUser({
					...user,
					token: token,
					name: usr?.user?.username,
					image: usr?.user?.image,
					email: usr?.user?.email,
				});
			} else {
				CookieToken.removeToken(ACCESS_TOKEN_KEY);
				Token.removeToken(REFRESH_TOKEN_KEY);
			}
		}
		if (localStorage.getItem('userData') && !isTokenExpired(token)) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	const fetchTopBarContent = async () => {
		await apiClient.get('top-bar')
			.then((res) => {
				setTopBarContent(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchUtmParams = () => {
		setUtmParams(JSON.parse(localStorage.getItem("utm_params")));
	};

	useEffect(() => {
		fetchTopBarContent();
	}, []);

	useEffect(() => {
		fetchUtmParams();
	}, []);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className='bg-[#F4F4F4] sticky top-0 z-20 w-full'>
			<div className='bg-[#052F3F] flex items-center justify-between w-full gap-8 py-2 pr-6 text-[1.2rem] font-[400] text-[#F4F4F4] max-lg:flex-wrap max-lg:px-[1rem] pl-12 max-lg:gap-2'>
				<div className={"text-[14px] text-white whitespace-nowrap max-xl:whitespace-normal " + (!topBarContent?.visibility && 'hidden')}>
					<span className="font-extrabold text-green-400">
						{topBarContent?.greenText ?? "New Year Sale Alert!"}{' '}
					</span>
					<span className="font-light">
						{topBarContent?.whiteText ?? "From Dec 16, 2024, to Jan 30, 2025,"}{' '}
					</span>

					<span className="font-bold text-yellow-400 animate-pulse">
						{topBarContent?.yellowText ?? "US $1,499!"}{' '}
					</span>
					<span className="font-light italic text-gray-300">
						&nbsp;&nbsp;{topBarContent?.tncText ?? "*T&C Apply"}
					</span>
				</div>

				<div className='flex items-center justify-end w-full gap-8 py-2 pr-6 text-[1.2rem] font-[400] text-[#F4F4F4] max-lg:flex-wrap max-lg:px-[1rem] max-lg:gap-2'>
					<div className='flex items-center gap-2'>
						<BsTelephone className='text-[#F4F4F4] font-[400]' strokeWidth={1}
						/>
						<a href='tel:+13473542413'>+1 (347) 354-2413 (US)</a>
					</div>
					<div className='flex items-center gap-2'>
						<BsTelephone className='text-[#F4F4F4] font-[400]' strokeWidth={1} />
						<a href='tel:+442037695086'>+44 20 3769 5086 (UK)</a>
					</div>
					<div className='flex items-center gap-2'>
						<BsTelephone className='text-[#F4F4F4] font-[400]' strokeWidth={1} />
						<a href='tel:+918134945168'>+91 81349 45168 (IN)</a>
					</div>
					<div className='flex items-center gap-2'>
						<CiMail className='text-[#F4F4F4] font-[400]' strokeWidth={1} />
						<a href='mailto:inquiry@horizon-markets.com'>inquiry@horizon-markets.com</a>
					</div>
				</div>
			</div>
			<div className='flex justify-between items-center w-[90%] max-xl:w-[95%] mx-auto md:px-custom'>
				<Link
					to={
						utmParams ?
							`/?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
							:
							'/'
					}
					className='navbar__Left'
				>
					<img
						src='/HMSLogo.png'
						alt='HMS Logo'
						className='w-[8.5rem] h-auto'
					/>
				</Link>
				<div className='group hidden max-md:block'>
					{/* {isReportDetailsPage && (
						<button
							onClick={handleDownloadSample}
							className=" border-black/80 border-[1px] bg-[#d8d8f6] px-[2rem] py-[1.2rem] text-[#000] text-[14px] font-[400] rounded-xl hover:shadow-none outline-none"

						>
							Download Free Sample
						</button>
					)} */}

					{/* {isReportDetailsPage && 
					( */}
					{/* <Link
						to={
							utmParams ?
								`/byor?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
								:
								'/byor'
						}
						// className='flex items-center justify-between gap-[1rem] button__Byor w-full new__byor__btn bg-transparent hover:bg-[#052F3F] text-[#052F3F] px-4 py-2 rounded-full hover:text-[#F4F4F4]'
						className="flex items-center  gap-[0.5rem] button__Byor new__byor__btn bg-transparent hover:bg-[#052F3F] text-[#01212E] px-4 py-2 rounded-full text-[14px] hover:text-white"
						target='_blank'
					>
						<span className='ml-2 text-[14px]'>
							Build Your Own Report
						</span>
						<div
							className="mr-2 bg-[#052F3F] group-hover:bg-[#052F3F] text-white text-[14px] flex items-center justify-center px-4 py-2 rounded-full"
						>
							New
						</div>
					</Link> */}
					<Link
	to={
		utmParams
			? `/byor?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
			: '/byor'
	}
	className="flex items-center justify-between gap-[0.5rem] button__Byor new__byor__btn bg-transparent hover:bg-[#052F3F] text-[#01212E] px-4 py-2 rounded-full text-[14px] hover:text-white"
	target="_blank"
>
	<span className="ml-2 text-[14px] hover:text-white">  {/* Ensure hover text color is applied */}
		Build Your Own Report
	</span>
	<div
		className="mr-2 bg-[#052F3F] text-white text-[14px] flex items-center justify-center px-4 py-2 rounded-full"
	>
		New
	</div>
</Link>
					{/* )} */}
				</div>


				<div className='w-full md:flex md:w-auto max-lg:hidden'>
					<ul className='nav__MainLinks'>
						<li className='group'>
							<button className='gap-[1rem] flex items-center justify-between w-full py-2 px-3 font-[400] text-[#01212E] text-[14px] hover:bg-[#052F3F] rounded-lg hover:text-[#F4F4F4]'>
								Industries
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block bg-[#F4F4F4] fixed top-[9rem] mt-3 h-fit overflow-y-scroll text-[14px]'>
								<div className='grid grid-cols-2 grid-rows-8 grid-flow-col gap-4 p-4 w-full mx-auto py-12'>
									{!isLoading && !error && category?.length > 0 && [...category].sort((a, b) => a.name.localeCompare(b.name)).map((e) => (
										<div key={e.id} className='relative group nvf'>
											<NavLink
												to={
													utmParams ?
														`/reports/${e.slug}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
														:
														`/reports/${e.slug}`
												}
												className='flex text-[#01212E] px-2 py-1 rounded-[0.5rem] hover:underline !text-[16px]'>
												{e.name}
											</NavLink>
											{/* <div className='nvf absolute left-0 overflow-y-scroll max-h-[30rem] top-[100%] hidden group-hover:bg-[#0e3545] group-hover:flex z-[1] flex-col min-w-[25rem]'>
												{e.sub_categories.map((i) => (
													<NavLink
														key={i.id}
														className='renderCategory__Child'
														to={`/reports/${i.id}`}>
														{i.name}
													</NavLink>
												))}
											</div> */}
										</div>
									))}
								</div>
								{/* <IndustriesDropdownContent /> */}
							</div>
						</li>
						<li className='group'>
							<button className='flex items-center justify-between gap-[0.5rem] w-full py-2 px-3 font-[400] text-[#01212E] text-[14px] hover:bg-[#052F3F] rounded-lg hover:text-[#F4F4F4]'>
								Company
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block bg-[#EDEDED] fixed top-[8rem] mt-3 h-fit overflow-y-scroll'>
								<CompanyDropdown utmParams={utmParams} />
							</div>
						</li>
						{/* <li className='group'>
							<button className='flex items-center gap-[1rem]'>
								Resources
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block mt-1 bg-[#EDEDED] fixed top-[9rem] h-fit overflow-y-scroll'>
								<ResourcesDropdown />
							</div>
						</li> */}
						{/* <li
							className='group'
							onClick={() => navigate('/byor')}
						>
							<button
								className='flex items-center gap-[1rem] button__Byor new__byor__btn hover:bg-[#052F3F]'
							>
								<span className='ml-2'>
									
									Build Your Own Report
								</span>
								<span
									className="mr-2 bg-[#5451D6] text-white text-[16px] flex items-center justify-center px-4 py-2 rounded-full "
								>
									New
								</span>
							</button>
						</li> */}
						{/* <li
							className="group"
							onClick={
								() => navigate(
									utmParams ?
										`/byor?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
										:
										'/byor'
								)
							}
						>
							<button
								className="flex items-center gap-[0.5rem] button__Byor new__byor__btn bg-transparent hover:bg-[#052F3F] text-[#01212E] px-4 py-2 rounded-full text-[14px]"
							>
								<span className="ml-2">
									Build Your Own Report
								</span>
								<span
									className="mr-2 bg-[#5451D6] group-hover:bg-[#052F3F] text-white text-[14px] flex items-center justify-center px-4 py-2 rounded-full"
								>
									New
								</span>
							</button>
						</li> */}

						<li className='group'>
							<button
								className="flex items-center gap-[0.5rem] button__Byor new__byor__btn bg-transparent hover:bg-[#052F3F] text-[#01212E] px-4 py-2 rounded-full text-[14px]"
								onClick={() => {
								const url = utmParams
									? `/byor?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
									: "/byor";
								window.open(url, "_blank");
								}}
							>
								<span className="ml-2">Build Your Own Report</span>
								<span className="mr-2 bg-[#052F3F] group-hover:bg-[#052F3F] text-white text-[14px] flex items-center justify-center px-4 py-2 rounded-full">
								New
								</span>
							</button>
							</li>

						{/* <li>
							<Link to='/faqs'>FAQs</Link>
						</li> */}
						<li>
							<Search onClick={() => openSearchModal()} className='cursor-pointer' />
							{
								searchModal && (
									<SearchModal closeSearchModal={closeSearchModal} utmParams={utmParams} />
								)
							}
						</li>
						{/* <li>
							<Popover>
								<PopoverTrigger onClick={() => navigate('/dashboard')}>
									<UserCircle />
								</PopoverTrigger> */}
						{/* <PopoverContent className='flex p-4 bg-[#272626] text-white w-32 rounded-md text-xl'>
									<ul className='flex flex-col gap-5'>
										{
											loggedIn ? (
												<>
													<li className='cursor-pointer'>
														<Link to='/dashboard'>Profile</Link>
													</li>
													<li className='cursor-pointer'>
														<Link to='/dashboard'>Orders</Link>
													</li>
													<li onClick={logout} className='cursor-pointer'>
														Logout
													</li>
												</>
											) : (
												<>
													<li className='cursor-pointer'>
														<Link to='/sign-in'>Login</Link>
													</li>
												</>
											)
										}
									</ul>
								</PopoverContent> */}
						{/* </Popover>
						</li> */}
					</ul>
				</div>

				{/* Responsive Menu */}
				<div className="md:hidden relative">
					<button onClick={toggleMenu} className="text-2xl">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="w-8 h-8"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
							/>
						</svg>
					</button>
					{isMenuOpen && (
						<div className="absolute right-0 bg-white shadow-lg w-[25rem] z-50">
							<ul className="menu p-4">
								<li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('industries')}
										className="flex justify-between items-center w-full text-[1.2rem] hover:text-[#F4F4F4] hover:bg-[#052F3F]"
									>
										Industries
									</button>
									{activeMenu === 'industries' && (
										<ul className="bg-gray-100 p-2">
											{category?.map((item) => (
												<li key={item.id}>
													<NavLink
														to={
															utmParams ?
																`/reports/${item.slug}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
																:
																`/reports/${item.slug}`
														}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 hover:text-[#F4F4F4] hover:bg-[#052F3F] text-[1rem]"
													>
														{item.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li>
								<li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('company')}
										className="flex justify-between items-center w-full text-[1.2rem] hover:text-[#F4F4F4] hover:bg-[#052F3F]"
									>
										Company
									</button>
									{activeMenu === 'company' && (
										<ul className="bg-gray-100 p-2">
											{companyLinks.map((items) => (
												<li key={items.id}>
													<NavLink
														to={
															utmParams ?
																`${items.path}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
																:
																items.path
														}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 text-[1rem] hover:text-[#F4F4F4] hover:bg-[#052F3F]">
														{items.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li>
								{/* <li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('resources')}
										className="flex justify-between items-center w-full text-[1.2rem]"
									>
										Resources
									</button>
									{activeMenu === 'resources' && (
										<ul className="bg-gray-100 p-2">
											{resourcesLinks?.map((items) => (
												<li key={items.id}>
													<NavLink
														to={items.path}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 text-[1rem] hover:bg-gray-200">
														{items.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li> */}
								{/* <li className='mb-2'>
									<Link to='/byor'
										className='flex items-center justify-between gap-[1rem] button__Byor w-full'
									>
										<span className='ml-2 text-[1.2rem]'>
											Build Your Own Report
										</span>
										<div
											className="mr-2 bg-[#9b9aef] hover:bg-[#052F3F]  text-white text-[1.2rem] flex items-center justify-center px-4 py-2 rounded-full"
										>
											New
										</div>
									</Link>
								</li> */}
								<li>
									<Link
										to={
											utmParams ?
												`/faqs?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
												:
												'/faqs'
										}
										onClick={() => setIsMenuOpen(!isMenuOpen)}
										className='text-[1.2rem] hover:underline'
									>FAQs</Link>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
};

const SearchModal = ({ closeSearchModal, utmParams }) => {
	const firstInputRef = useRef(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleClose = (e) => {
		if (e.target.classList.contains('fixed')) {
			closeSearchModal();
		}
	};

	const fetchResults = async (query) => {
		if (!query) {
			setResults([]);
			return;
		}
		setLoading(true);
		try {
			const response = await apiClient.get(
				`/research-documents`,
				{
					params: {
						populate: '*',
						filters: {
							title: {
								$containsi: query,
							},
						},
					},
				}
			);
			setResults(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			fetchResults(searchQuery);
		}, 500); // Debounce API calls
		return () => clearTimeout(timeoutId);
	}, [searchQuery]);

	useEffect(() => {
		if (firstInputRef.current) {
			firstInputRef.current.focus();
		}
	}, []);

	return (
		<div className='fixed top-[10vh] left-[20vh] w-[80vw] h-[80vh] bg-[#042E3E]/95 z-[100] pt-20 flex flex-col gap-4 items-center rounded-xl' onClick={handleClose}>
			<button
				onClick={closeSearchModal}
				style={{
					color: "#fff",
					position: "absolute",
					right: "10px",
					top: "10px",
					width: "fit-content",
				}}
			>
				<XIcon />
			</button>
			<div className='w-[70%] flex flex-col items-center justify-center gap-4'>
				<input
					className='w-full text-[14px] text-white text-center p-2 bg-transparent border-b-2 border-[#fff] outline-none placeholder:text-[14px] placeholder:text-[#F4F4F4] font-[400]'
					type='text'
					placeholder='Search...'
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					ref={firstInputRef}
				/>

				<div className='w-full h-[55vh] overflow-y-scroll' onClick={handleClose}>
					{
						loading ?
							<Loader />
							: results.length > 0 ?
								results.map((result) => (
									<Link
										key={result.id}
										to={
											utmParams ?
												`/report/${result.slug}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
												:
												`/report/${result.slug}`
										}
										onClick={closeSearchModal}
										style={{
											color: "#fff",
										}}
										className='underline-offset-4 hover:underline'
									>
										<h1>{result.title}</h1>
									</Link>
								))
								: <p>No results found</p>
					}
				</div>
			</div>
		</div>
	);
};

const IndustriesDropdownContent = () => (
	<div className='nav__Cont w-[90%] mx-auto'>
		<div className='nav__BoxThree'>
			<div className='nav__BoxThree--Left'>
				<h1>Industries</h1>
				<p>
					From brand strategy to sales performance, we blend a unique combination
					of expertise, advanced analytics, products and platforms, creating the
					most meaningful understanding of how people think and act.
				</p>
			</div>
		</div>
		<ContactSection />
	</div>
);

const ResourcesDropdown = () => {
	const resourcesLinks = navLinks.articleLinks;

	return (
		<>
			<div className='grid grid-cols-1 gap-4 p-4 w-full mx-auto py-12'>
				{resourcesLinks?.map((items) => (
					<Link
						key={items.id}
						to={items.path}
						className='flex text-white hover:underline capitalize'>
						{items.name}
					</Link>
				))}
			</div>
			{/* <div className='w-[90%] mx-auto'>
				<h1 className='text-[3.2rem] font-[400] text-[#FFF]'>
					Capabilities
				</h1>
				<p className='mt-[2rem] text-[1.6rem] font-[500] text-[#FFF] max-w-[70%]'>
					From brand strategy to sales performance, we blend a unique combination 
					of expertise, advanced analytics, products and platforms, creating the 
					most meaningful understanding of how people think and act.
				</p>
				<ContactSection />
			</div> */}
		</>
	);
};

const CompanyDropdown = ({ utmParams }) => {
	const companyLinks = navLinks.companyLinks;
	const articleLinks = navLinks.articleLinks;

	return (
		<div>
			{/* <h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap mb-[2rem]'>
				COMPANY
			</h1> */}
			<div className='grid grid-cols-1 gap-4 p-4 w-full mx-auto py-12'>
				{companyLinks.map((items) => (
					<Link
						key={items.id}
						to={
							utmParams ?
								`${items.path}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}`
								:
								items.path
						}
						className='flex text-[#01212E] capitalize hover:underline px-2 py-1 rounded-[0.5rem] !text-[16px]'>
						{items.name}
					</Link>
				))}
			</div>

			{/* <h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap my-[2rem]'>
				ARTICLE
			</h1>
			<div className='grid grid-cols-4 gap-4 p-4 mx-auto'>
				{articleLinks.map((items) => (
					<Link
						key={items.id}
						to={items.path}
						className='renderCategory__name flex justify-center'>
						{items.name}
					</Link>
				))}
			</div>
			<ContactSection /> */}
		</div>
	);
};

const ContactSection = () => (
	<div className='nav__dropdownDetails'>
		<div className='nav__dropdownLeft flex gap-[8rem]'>
			<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
				<h1 className='uppercase'>Contact</h1>
				<a href='tel:+447700142117' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					+44 7700 142117
				</a>
				<a href='tel:+12136167815' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					+1 213 616 7815
				</a>
			</div>
			<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
				<h1 className='uppercase'>Email</h1>
				<a href='mailto:info@horizon-markets.com' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					info@horizon-markets.com
				</a>
			</div>
			<div className='nav__InfoOne flex flex-col'>
				<h1 className='uppercase'>Follow</h1>
				<div className='flex gap-[1rem]'>
					<Link to='https://www.linkedin.com/company/horizon-market-strategies/' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Facebook
						</span>
					</Link>
					<Link to='https://www.linkedin.com/company/horizon-market-strategies/' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Linkedin
						</span>
					</Link>
					<Link to='https://www.youtube.com/@HorizonmarketStrategies' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Youtube
						</span>
					</Link>
				</div>
			</div>
		</div>
		<div className='nav__dropdownRight'>
			<Link to='/sign-in'>
				<Button
					type='button'
					text='Login'
					className='mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]'
				/>
			</Link>
		</div>
	</div>
);

export default Navbar;